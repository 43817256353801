<template>
  <div class="drop-down-setting">
    <b-dropdown id="dropdown-right-setting" right variant="none" class="m-2">
      <template #button-content>
        <div class="button-vox-style">
          <div class="box-user-details-style">
            <span
              class="name-user-style"
              v-if="getUserData && getUserData.name"
            >
              {{ getUserData.name }}</span
            >
            <span
              class="role-user-style"
              v-if="getUserData && getUserData.role"
            >
              {{ getUserData.role | formatRoleUser }}</span
            >
          </div>
          <div class="icon-user-style" :style="computedImageUser">
            <font-awesome-icon icon="user" class="icon" />
          </div>
        </div>
      </template>
      <b-dropdown-item href="/setting">
        Paramètres
      </b-dropdown-item>

      <b-dropdown-divider></b-dropdown-divider>

      <b-dropdown-item @click="deconnexion">
        Se déconnecter
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(['logOut']),
    deconnexion() {
      this.logOut();
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'getErrorExportAllFactureCdg',
      'getLoadingExportAllFactureCdg',
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'isSuperAdminGetter'
    ]),

    computedImageUser() {
      if (this.getUserData && this.getUserData.image) {
        return {
          'background-image': 'url(' + `${this.getUserData.image}` + ')'
        };
      } else {
        return { 'background-color': '#F2F2F2' };
      }
    }
  },
  filters: {
    formatRoleUser: value => {
      switch (value) {
        case 'contabilite':
          return 'Comptabilité';
        case 'responsable_dnh':
          return 'Responsable GE CDG';
        case 'responsable_web_abondan':
          return 'Responsable PINKMAN';
        case 'responsable_acteo':
          return ' Responsable ALTEO FRANCE';
        case 'responsable_sav_s':
          return ' Responsable SAMI-B';

        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
#dropdown-right-setting {
  margin-top: 0px !important;
  button {
    display: flex;
    line-height: 16px;
    padding-top: 3px;
    .button-vox-style {
      display: flex;
      .box-user-details-style {
        display: grid;
        font-family: 'Montserrat', sans-serif;
        text-align: end;
        margin: auto;
        margin-right: 12px;
        .name-user-style {
          color: #545454;
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 3px;
        }
        .role-user-style {
          font-size: 10px;
          font-weight: 400;
          color: #aaaaaa;
        }
      }
      .icon-user-style {
        width: 42px;
        height: 42px;
        overflow: hidden;
        background-size: contain;
        background-position: 50%;
        border-radius: 50px;
        background-repeat: no-repeat;
        .icon {
          color: white;
          height: 30px;
          font-size: 25px;
        }
      }
    }
  }
  .dropdown-toggle::after {
    content: url('../../assets/Icon material-arrow-drop-down.png');
    width: 14px;
    background-size: 5px;
    height: 14px;
    font-size: 1px;
    position: relative;
    padding: 1px;
    padding-top: 2px;
    right: 11px;
    top: 27px;
    border-radius: 50%;
    background-color: #4d4bac;
  }
  .dropdown-menu {
    margin: 0;
    border-radius: 0;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #aaaaaa42;
    border: none;
    transform: translate3d(-23px, 52px, 0px) !important;
    li {
      margin: 0px 5px;
    }
    .dropdown-item {
      border-radius: 25px;
      margin: 1px 0px;
      padding: 6px 8px;

      &.router-link-active {
        color: #fff;
        background-color: #4d4bac;
      }
      &:active {
        background-color: #4d4bac;
      }
      &:hover {
        color: #fff;
        background-color: #9799d6;
        border-radius: 25px;
      }
    }
  }
}
</style>
